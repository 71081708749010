//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from "qs";
export default {
  name: "TheHeader",
  data() {
    return {
      menu: [],
      navbarOpen: false,
      isDesktop: null,
    };
  },
  async fetch() {
    const params = {
      nested: true,
      populate: {
        items: {
          populate: {
            page: true,
          },
        },
      },
    };

    const query = qs.stringify(params, { addQueryPrefix: true });

    this.menu = await fetch(`${process.env.api_url}/api/menus/2${query}`).then(
      (res) => res.json()
    );
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    handleResize() {
      this.isDesktop = parseInt(window.innerWidth) >= 1400;
      this.$store.commit("showNavbar", this.isDesktop);
      // this.isDesktop && this.showNavbar();
      this.isDesktop && this.shortenNavbar();
    },
    onResize() {
      this.isDesktop = parseInt(window.innerWidth) >= 1400;
      this.$store.commit("showNavbar", this.isDesktop);
      // this.isDesktop && this.showNavbar();
      this.isDesktop && this.shortenNavbar();
    },

    shortenNavbar() {
      var shortenNav = this.$gsap.to(".main-header", {
        y: "-20px",
        duration: 0.25,
        ease: "power2.in",
        paused: true,
      });
      var shortenLogo = this.$gsap.to(".main-logo", {
        width: "50px",
        alignSelf: "center",
        duration: 0.5,
        ease: "power2.in",
        paused: true,
      });

      this.$ScrollTrigger.create({
        trigger: ".desktop-size",
        start: "10px top",
        end: 99999,
        onUpdate: ({ progress, direction, isActive }) => {
          if (this.isDesktop) {
            if (progress <= 0.001) {
              shortenNav.reverse();
              shortenLogo.reverse();
            }
            if (direction > 0.001) {
              shortenNav.play();
              shortenLogo.play();
            }
            return;
          }
        },
      });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.$nextTick(function () {
      this.onResize();
    });
    window.addEventListener("resize", this.onResize);
  },
};
